<template>
  <div  id="main">
    <DFSHeader active="检索" />
    <div id="main-content">
      <a-table
          class="wrapper"
          :loading="loading"
          :pagination="pagination"
          :columns="columns"
          :data-source="species"
          :row-key="speciesItem => speciesItem.slug"
          @change="onChange"
      >
          <span slot="slug" slot-scope="text">
            <a @click="searchByQ(text)">{{ text }}</a>
          </span>
        <template slot="sciname" slot-scope="text">
          <span v-html="text"></span>
        </template>
      </a-table>
    </div>
    <DFSFooter />
  </div>
</template>
<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import { list } from '@/services/species.js'
import { columns } from './utils';
export default {
    data() {
        return {
            loading: false,
            pagination: {},
            columns,
            species: []
        }
    },
    components: {
        DFSHeader,
        DFSFooter,
    },
    mounted() {
        let search = this.$route.query
            this.queryParams = search
        this.getList()
    },
    methods: {
        getList(params) {
            if(!params)this.queryParams.page = 1
            this.loading = true
            list(this.queryParams).then(({data}) => {
                this.loading = false;
                const pager = { ...this.pagination };
                pager.total = data.data.count;
                this.pagination = pager;
                this.species = data.data.results;
            })
        },
        onChange(pagination) {
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            this.queryParams.page = pagination.current;
            this.getList(this.queryParams);
        },
        searchByQ(q) {
            this.$router.push('/species/' + q);
        },
    }
}
</script>
<style lang="stylus" scoped>
#main-content
  padding-top: 2rem
</style>
