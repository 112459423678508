export const columns = [
  {
    title: '编号',
    dataIndex: 'slug',
    width: 150,
    fixed: 'left',
    scopedSlots: {
      customRender: 'slug'
    }
  },
  {
    title: '中文名',
    width: 150,
    dataIndex: 'name'
  },
  {
    title: '拉丁名',
    dataIndex: 'sciname',
    width: 400,
    scopedSlots: {
      customRender: 'sciname'
    }
  }
];